$(function() {
    
    // Media modal
    const mediaModalHtml = $(/* html */`
        <div class="media-modal modal fade" id="mediaModal" tabindex="-1" aria-hidden="true">
            <div class="media-modal__dialog modal-dialog modal-dialog-centered">
                <div class="media-modal__content modal-content">
                    <button aria-label="Close" class="media-modal__close close" data-dismiss="modal" type="button"></button>
                    <div class="media-modal__body modal-body">
                        <div class="embed-responsive embed-responsive-16by9" id="mediaModalEmbed">
                        </div>
                        <div class="bar-loader">
                            <div class="bar-loader__track"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `).appendTo('body');

    const mediaModal = $('#mediaModal');

    mediaModal.on('hidden.bs.modal', function () {
        $(this)
            .find('#youtubeIframe')
            .remove();
    });
    

    // Media link click
    const youtubeToggle = $('[data-yt-id]');
    const youtubeIframeHtml = $(`<iframe class="embed-responsive-item" id="youtubeIframe" src="" title="YouTube media player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`);

    youtubeToggle.each(function (index) {
        const $ytToggle = $(this);
        const ytId = $ytToggle.data('yt-id');
        
        $ytToggle
            .attr({
                'data-toggle': 'modal',
                'data-target': '#mediaModal'
            })
            .parent()
            .addClass('js-has-video');
        
        const youtubeIframe = youtubeIframeHtml.clone().attr('src', 'https://www.freshpaint-hipaa-videos.com/youtube/embed/' + ytId + '?autoplay=1');
        
        $ytToggle.on('click', function (e) {
            if (window.matchMedia('(min-width: 992px)').matches) {
                mediaModal
                    .removeClass('js-iframe-loaded')
                    .find('#mediaModalEmbed')
                    .append(youtubeIframe);
    
                mediaModal.find('#youtubeIframe').on('load', function () {
                    mediaModal.addClass('js-iframe-loaded');
                });
            } else {
                e.stopPropagation();
                
                $('<div class="mobile-embed embed-responsive embed-responsive-16by9" id="mediaEmbed' + index + '">').insertBefore($ytToggle);
                
                youtubeIframe.clone().appendTo('#mediaEmbed' + index + '');
            }
        });

        $(window).on('resize', function () {
            if (window.matchMedia('(min-width: 992px)').matches) {
                $('.mobile-embed').remove();
            } else {
                $('#mediaModal').modal('hide');
            }
        });
    });

    $(document).trigger($.Event('media.modal.ready'));
});